import { routeNamesType } from '@route-names';
import { Odkaz } from '@core/models/odkaz';
import { Uzivatel } from '@partner/models/uzivatel';

export interface AppModel {
  messages: Message[],
  spodniOdkazy: Odkaz[],
  bezpecnostniMetodyOdkazy: ExtendedOdkaz[],
  uzivatel: Uzivatel,
  env: EnvConfig
}

export interface Message {
  text: string;
  type: MessageType;
  routeName: routeNamesType;
}

export enum MessageType {
  error = 'danger',
  warning = 'warning',
  info = 'info',
  success = 'success'
}

export interface EnvConfig {
  readonly urlPrefix: string;
  readonly bezpecnostniRozcestnikVyveskaUrl: string;
  readonly odkazyUrl: string;
  readonly internalModulUrl: string;
  readonly zapomenuteHesloUrl: string;
  readonly potvrzovaciKodUrl: string;
  readonly expiraceHeslaUrl: string;
  readonly modulName: string;
  readonly spravaUzivateleVyveskaUrl: string;
  readonly uzivatelUrl: string;
  readonly zmenaHeslaUrl: string;
  readonly nastavHesloUrl: string;
  readonly posliAutorizacniSmsUrl: string;
  readonly logoutUrl: string;
  readonly clearSession: string;
}

export interface ExtendedOdkaz extends DoplnujiciNastaveniOdkazu, Odkaz {
}

export interface DoplnujiciNastaveniOdkazu {
  odkazName: string;
  /**
   * AuthLevel id
   */
  id: string;
  /**
   * Název icony pro zobrazení
   */
  icon?: string;

  /**
   * Říká jestli je to tlačítko brané jako primární
   */
  primary?: boolean;
}
